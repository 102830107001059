<footer class="footer-area footer-bg2 pt-100">


    <a href="https://wa.me/9895373751" target="_blank" id="whatsapp-button">
        <i class="fab fa-whatsapp"></i>
    </a>
   
    

    <div class="container">
        <div class="footer-midal pb-70">
            <div class="row">
                <div class="col-lg-4 col-sm-7">
                    <div class="footer-widget">
                        <div class="footer-img"><img
                                src="assets/img/logo/logo-blue.png"
                                alt="Images">
                        </div>
                        <p>Ipromise Solutions (OPC) Private Limited is a
                            leadingWeb , Mobile App Development & e-Governance
                            Support providing Startup company in
                            Thiruvananthapuram,India.</p>
                        <div class="footer-social-icon"><ul class="social-link">
                                <li><a href="#" target="_blank"><i
                                            class='bx bxl-facebook'></i></a></li>
                                <li><a href="#" target="_blank"><i
                                            class='bx bxl-twitter'></i></a></li>
                                <li><a href="#" target="_blank"><i
                                            class='bx bxl-instagram'></i></a></li>
                                <li><a href="#" target="_blank"><i
                                            class='bx bxl-pinterest-alt'></i></a></li>
                                <li><a href="#" target="_blank"><i
                                            class='bx bxl-youtube'></i></a></li></ul>
                        </div>
                    </div>
                </div>

                <div class="col-lg-2 col-sm-5">
                    <div class="footer-widget">
                        <h3>Resources</h3>

                        <ul class="footer-list"><li><a
                                    routerLink="/ipromise-website">Web
                                    Sites</a></li><li><a
                                    routerLink="/ipromise-mobileapps">Mobile
                                    Apps</a></li><li><a
                                    routerLink="/ipromise-ecommerce">eGovernance and
                                    Consultancy</a></li><li><a
                                    routerLink="/ipromise-socialmedia">Social
                                    Media</a></li>
                                    <li><a
                                        routerLink="/ipromise-socialmedia">

                                    Graphic Designing</a></li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-7">
                    <div class="footer-widget pl-5">
                        <h3>Company</h3>

                        <ul class="footer-list"><li><a
                                    routerLink="/">Home</a></li><li><a
                                    routerLink="/about">About</a></li><li><a
                                    routerLink="/services">Services</a></li><li><a
                                    routerLink="/contact">Contact</a></li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-5">
                    <div class="footer-widget">
                        <h3>Address</h3>

                        <ul class="footer-list-two"><li><i
                                    class='flaticon-telephone'></i><a
                                    href="tel:9895373751">+91 9895 3737
                                    51</a></li><li><i
                                    class='flaticon-email-1'></i><a
                                    href="mailto:hello&#64;ribo.com">ceo&#64;ipromisesolutions.com</a></li><li><i
                                    class='flaticon-place'></i> H.O: IInd Floor,Penta
                                City Tower,Opp.PRS Hospital, Killippalam,
                                Trivandrum, IN</li>

                                <li><i
                                    class='flaticon-place'></i>B.O: 35, Police Kandaswami Street, Ramanathapuram, Coimbatore </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="copy-right-area">
            <div class="row">
                <div class="col-lg-8">
                    <div class="copy-right-text text-start">
                        <p>© 2024 promise Solutions (OPC) Private Limited <a
                                href="https://ipromisesolutions.com/"
                                target="_blank">All Rights Reserved. </a></p>
                    </div>
                </div>

                <div class="col-lg-4">
                    <div class="copy-right-list">
                        <ul><li><a routerLink="/privacy-policy"> Privacy
                                    Policy</a></li><li><a
                                    routerLink="/terms-conditions"> Terms &
                                    Conditions</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>