<app-navbar-style-two></app-navbar-style-two>

<div class="inner-banner">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Portfolio</h3>
            <ul>
                <li><a href="index.html">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Portfolio</li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Artificial Intelligence (AI)</li>

            </ul>
        </div>
    </div>

    <div class="inner-banner-shape">
        <div class="shape-one"><img
                src="assets/img/inner-banner/banner-shape1.png"
                alt="Images"></div>
        <div class="shape-two"><img
                src="assets/img/inner-banner/banner-shape2.png"
                alt="Images"></div>
        <div class="shape-three"><img
                src="assets/img/inner-banner/banner-shape3.png"
                alt="Images"></div>
        <div class="inner-banner-dots"><img
                src="assets/img/shape/dots-shape.png" alt="Images"></div>
    </div>
</div>

<div class="case-study-area pt-50 pb-70">
    <div class="container">
        <div class="row">

            <div class="col-lg-8 col-md-12">
                <div class="row">

             
                    <div class="col-lg-6 col-md-6">
                        <div class="case-study-card">
                            <a routerLink="/case-study-details">
                                <img src="assets/img/case-studies/ai1.png"
                                    alt="Detection Project">
                            </a>
                            <div class="content">
                                <h3><a href="#">
                                       DELTALABS QATAR</a></h3>
                         
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="case-study-card">
                            <a routerLink="/case-study-details">
                                <img src="assets/img/case-studies/ai2.png"
                                    alt="Customer Segmentation">
                            </a>
                            <div class="content">
                                <h3><a href="#">DELTALABS QATAR
                                        </a></h3>
                                
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="case-study-card">
                            <a routerLink="/case-study-details">
                                <img src="assets/img/case-studies/ai3.png"
                                    alt="Detection Project">
                            </a>
                            <div class="content">
                                <h3><a href="#">DELTALABS QATAR</a></h3>
                             
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="case-study-card">
                            <a routerLink="/case-study-details">
                                <img src="assets/img/case-studies/ai4.png"
                                    alt="Detection Project">
                            </a>
                            <div class="content">
                                <h3><a href="#">DELTALABS QATAR
                                        </a></h3>
                                
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="case-study-card">
                            <a routerLink="/case-study-details">
                                <img src="assets/img/case-studies/ai5.png"
                                    alt="Customer Segmentation">
                            </a>
                            <div class="content">
                                <h3><a href="#">IPROMISE </a></h3>
                               
                            </div>
                        </div>
                    </div>

                

     
                </div>

            </div>

            <div class="col-lg-4 col-md-12">
                <div class="services-widget-right">
                    <div class="widget-category">
                        <h3>Our Services</h3>

                        <ul>
                            <li><a routerLink="/services">Web
                                    Development</a></li>
                            <li><a routerLink="/services">Android
                                    Application</a></li>
                            <li><a routerLink="/services">IOS
                                    Applications</a></li>
                            <li><a routerLink="/services">IT/E-Governance Support
                                    Consultancy</a></li>
                            <li><a routerLink="/services">Social Media Marketing
                                </a></li>
                            <li><a routerLink="/services">Graphic Designing
                                </a></li>
                            <li><a routerLink="/services"> AI Videos
                                </a></li>
                        </ul>
                    </div>

                    <div class="contact-widget">
                        <h2>Contact Info</h2>

                        <ul>
                            <li>
                                <i class="flaticon-telephone"></i>
                                <div class="content">
                                    <h3>Phone: </h3>
                                    <span><a href="tel:9895373751">+ 91 989 5373
                                            751 </a></span>
                                </div>
                            </li>
                            <li>
                                <i class="flaticon-email-1"></i>
                                <div class="content">
                                    <h3>Email:</h3>
                                    <span><a
                                            href="mailto:ipromisesolutions&#64;gmail.com">ipromisesolutions&#64;<br>gmail.com</a></span>
                                </div>
                            </li>
                            <li>
                                <i class="flaticon-planet-earth"></i>
                                <div class="content">
                                    <h3>Corp. Office:</h3>
                                    <span>IInd Floor,Penta City Tower,
                                        Opp.PRS
                                        Hospital, Killippalam, Trivandrum,
                                        IN</span>
                                </div>

                                <div class="content">
                                    <h3>B.O:</h3>
                                    <span> 35, Police Kandaswami Street, 
                                        Ramanathapuram, 
                                        Coimbatore, IN </span>
                                </div>
                            </li>
                        </ul>
                    </div>

                    <div class="services-widget-two" style="display: none;">
                        <h2>Brochures</h2>

                        <ul>
                            <li>
                                <i class="flaticon-pdf-file"></i>
                                <div class="content">
                                    <h3>01:</h3>
                                    <span><a routerLink="#">PDF
                                            Download</a></span>
                                </div>
                            </li>
                            <li>
                                <i class="flaticon-pdf-file"></i>
                                <div class="content">
                                    <h3>02:</h3>
                                    <span><a routerLink="#">Services
                                            Details.txt</a></span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="seo-area">
    <div class="container-fluid">
        <div class="seo-width">
            <div class="row align-items-center">
                <div class="col-lg-8 col-md-12">
                    <div class="seo-form">
                        <h2>Contact with Our Experts?</h2>

                        <div class="seo-form-area">
                            <form class="seo-form-option">
                                <div class="row">
                                    <div class="col-lg-4 col-sm-6 col-md-4">
                                        <div class="form-group">
                                            <i class='fa fa-user'></i>
                                            <input
                                                class="form-control form-border"
                                                type="text"
                                                placeholder="Enter Your Name...">
                                        </div>
                                    </div>

                                    <div class="col-lg-4 col-sm-6 col-md-4">
                                        <div class="form-group">
                                            <i class='fa fa-phone'></i>
                                            <input class="form-control"
                                                type="text"
                                                placeholder="Enter Contact Number">
                                        </div>
                                    </div>

                                    <div
                                        class="col-lg-4 col-sm-6 col-md-4 offset-sm-3 offset-md-0 offset-lg-0">
                                        <button type="submit"
                                            class="default-btn">
                                            Send
                                            <i class='bx bx-plus'></i>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-12">
                    <div class="seo-img">
                        <img src="assets/img/seo-rank.png" alt="Images">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="brand-logo-area pt-100">
    <div class="container-fluid">
        <div class="container-max">
            <div class="brand-logo-slider owl-carousel owl-theme">
                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo1.png"
                        alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo2.png"
                        alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo3.png"
                        alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo4.png"
                        alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo5.png"
                        alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo6.png"
                        alt="Images">
                </div>
            </div>
        </div>
    </div>
</div>

<app-footer-style-two></app-footer-style-two>