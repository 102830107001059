<app-navbar-style-two></app-navbar-style-two>

<div class="banner-area-two">
    <div class="container-fluid">
        <div class="container-max-2">
            <div class="row align-items-center">
                <div class="col-lg-5 col-md-12">
                    <div class="banner-content-two">
                        <span>IPROMISE</span>
                        <h1>Industry Leading <b>Web Development </b> and <b>e-Governance </b> Support Services</h1>
                        <p>Ipromise Solutions Private Limited is a leading Web Development & eGovernance Support providing Startup company in Thiruvananthapuram, Kerala,India</p>
                        <div class="banner-content-btn">
                            <a href="/about" class="learn-btn">Learn More <i class='bx bx-plus'></i></a>
                        <!--   <a href="https://www.youtube.com/watch?v=07d2dXHYb94" class="play-on-btn popup-btn">Play Video <i class='flaticon-forward'></i></a> --> 
                        </div>
                    </div>
                </div>

                <div class="col-lg-7 col-md-12">
                    <div class="banner-img-2">
                        <img src="assets/img/home-two/home-two-img.png" alt="Images">
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="banner-bottom-shape">
        <div class="shape-one wow fadeInUp" data-wow-delay=".3s"><img src="assets/img/home-two/home-two-shape1.png" alt="Images"></div>
        <div class="shape-two wow fadeInUp" data-wow-delay=".5s"><img src="assets/img/home-two/home-two-shape2.png" alt="Images"></div>
        <div class="shape-three wow fadeInUp" data-wow-delay=".7s"><img src="assets/img/home-two/home-two-shape3.png" alt="Images"></div>
        <div class="banner-dots1"><img src="assets/img/home-two/home-two-dots.png" alt="Images"></div>
        <div class="banner-dots2"><img src="assets/img/home-two/home-two-dots2.png" alt="Images"></div>
    </div>
</div>

<div class="service-area-two" style ="display:none;">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-before sp-after">Services</span>
            <h2>Our Implementation Mission is to Serve You Best</h2>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-md-6">
                <div class="services-item">
                    <h3><a routerLink="/services-details2">Consumer Analytics</a></h3>
                    <div class="services-item-img">
                        <a routerLink="/services-details2"><img src="assets/img/service/service-icon7.png" alt="Images"></a>
                        <div class="img-circle"></div>
                    </div>
                    <p>Lorem ipsum dolor sit ametaut odiut perspiciatis unde omnis iste quuntur alquam quaerat rsit amet</p>
                    <a routerLink="/services-details2" class="learn-btn">Learn more <i class='bx bx-plus'></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-item">
                    <h3><a routerLink="/services-details2">Marketing Analytics</a></h3>
                    <div class="services-item-img">
                        <a routerLink="/services-details2"><img src="assets/img/service/service-icon8.png" alt="Images"></a>
                        <div class="img-circle"></div>
                    </div>
                    <p>Lorem ipsum dolor sit ametaut odiut perspiciatis unde omnis iste quuntur alquam quaerat rsit amet</p>
                    <a routerLink="/services-details2" class="learn-btn">Learn more <i class='bx bx-plus'></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="services-item">
                    <h3><a routerLink="/services-details2">Sales Analytics</a></h3>
                    <div class="services-item-img">
                        <a routerLink="/services-details2"><img src="assets/img/service/service-icon9.png" alt="Images"></a>
                        <div class="img-circle"></div>
                    </div>
                    <p>Lorem ipsum dolor sit ametaut odiut perspiciatis unde omnis iste quuntur alquam quaerat rsit amet</p>
                    <a routerLink="/services-details2" class="learn-btn">Learn more <i class='bx bx-plus'></i></a>
                </div>
            </div>
        </div>
    </div>

    <div class="service-two-shape">
        <div class="shape-in1"><img src="assets/img/shape/shape9.png" alt="Images"></div>
        <div class="shape-in2"><img src="assets/img/shape/shape10.png" alt="Images"></div>
        <div class="shape-in3"><img src="assets/img/shape/shape11.png" alt="Images"></div>
        <div class="shape-in4"><img src="assets/img/shape/shape7.png" alt="Images"></div>
        <div class="shape-in5"><img src="assets/img/shape/shape12.png" alt="Images"></div>
    </div>
</div>

<div class="mission-area pt-100">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="mission-content">
                    <div class="section-title">
                        <span class="sp-before sp-after">Our Expertize</span>
                       
                    </div>
                    <p>We design digital products that help to grow your businesses with in your affordable budget and within the time frame.We are also 
                        provides eGovernance Support services for Government Departments </p>
                 
                    <div class="row">
                        <div class="col-lg-6 col-sm-6 pr-0">
                            <div class="mission-list">
                                <i class='bx bx-check'></i>
                                <span >01</span>
                                <p>Web Development</p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-sm-6 pr-0">
                            <div class="mission-list">
                                <i class='bx bx-check'></i>
                                <span>02</span>
                                <p>eGovernance Consultancy</p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-sm-6 pr-0">
                            <div class="mission-list">
                                <i class='bx bx-check'></i>
                                <span>03</span>
                                <p>eOffice Support</p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-sm-6 pr-0">
                            <div class="mission-list">
                                <i class='bx bx-check'></i>
                                <span>04</span>
                                <p>ITcell Management</p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-sm-6 pr-0">
                            <div class="mission-list">
                                <i class='bx bx-check'></i>
                                <span>05</span>
                                <p>Social Media Marketing</p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-sm-6 pr-0">
                            <div class="mission-list">
                                <i class='bx bx-check'></i>
                                <span>06</span>
                                <p>Graphic Designing</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6 pr-0">
                            <div class="mission-list">
                                <i class='bx bx-check'></i>
                                <span>07</span>
                                <p>Mobile App Development</p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-sm-6 pr-0">
                            <div class="mission-list">
                                <i class='bx bx-check'></i>
                                <span>08</span>
                                <p>AI Videos</p>
                            </div>
                        </div>
  </div>
                 </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="mission-img">
                    <img src="assets/img/mission-img.png" alt="Images">
                </div>
            </div>
        </div>
    </div>

    <div class="mission-shape">
        <div class="shape1"><img src="assets/img/shape/shape13.png" alt="Images"></div>
        <div class="shape2"><img src="assets/img/shape/shape11.png" alt="Images"></div>
        <div class="shape3"><img src="assets/img/shape/shape12.png" alt="Images"></div>
        <div class="shape4"><img src="assets/img/shape/shape9.png" alt="Images"></div>
    </div>
</div>


<div class="about-area-two pb-70" style="display:none;">
    <div class="container-fluid">
        <div class="container-max">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="about-img-2">
                        <img src="assets/img/about/about-img2.png" alt="">
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="about-content-two">
                        <div class="section-title">
                            <span class="sp-before sp-after">
                                 Design & Development </span>
                            <h3>We design digital products that help to grow your businesses with in your affordable budget and within the time frame.</h3>
                        </div>
                        <h3 style="display:none;">We have 30 yearsOur strategy includes consistently evolving, to ensure we’re producing exceptional SEO for business.</h3>
                        <p style="display:none;">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna tetur adipisicing saliqua.</p>
                        <div class="row">
                            <div class="col-lg-6 col-sm-6 pr-0">
                                <div class="mission-list">
                                    <i class='bx bx-check'></i>
                                    <span >01</span>
                                    <p>Web Development</p>
                                </div>
                            </div>
                            <div class="col-lg-6 col-sm-6 pr-0">
                                <div class="mission-list">
                                    <i class='bx bx-check'></i>
                                    <span>02</span>
                                    <p>Mobile App Development</p>
                                </div>
                            </div>
                            <div class="col-lg-6 col-sm-6 pr-0">
                                <div class="mission-list">
                                    <i class='bx bx-check'></i>
                                    <span>03</span>
                                    <p>Website Maintainance</p>
                                </div>
                            </div>
                            <div class="col-lg-6 col-sm-6 pr-0">
                                <div class="mission-list">
                                    <i class='bx bx-check'></i>
                                    <span>04</span>
                                    <p>Google Business</p>
                                </div>
                            </div>
                            <div class="col-lg-6 col-sm-6 pr-0">
                                <div class="mission-list">
                                    <i class='bx bx-check'></i>
                                    <span>05</span>
                                    <p>Social Media Marketing</p>
                                </div>
                            </div>
                            <div class="col-lg-6 col-sm-6 pr-0">
                                <div class="mission-list">
                                    <i class='bx bx-check'></i>
                                    <span>06</span>
                                    <p>eGovernance Consultancy</p>
                                </div>
                            </div>

                            <div class="col-lg-6 col-sm-6 pr-0">
                                <div class="mission-list">
                                    <i class='bx bx-check'></i>
                                    <span>07</span>
                                    <p>e-Commerce</p>
                                </div>
                            </div>
                            <div class="col-lg-6 col-sm-6 pr-0">
                                <div class="mission-list">
                                    <i class='bx bx-check'></i>
                                    <span>08</span>
                                    <p>Graphic Designing</p>
                                </div>
                            </div>



                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="counter-area-two pt-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6 col-md-3">
                <div class="counter-card">
                    <h3> 170+</h3>
                    <p>Projects Completed</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-3">
                <div class="counter-card">
                    <h3>100%</h3>
                    <p>Satishfaction Rate</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-3">
                <div class="counter-card">
                    <h3>10+</h3>
                    <p>Team Memebers</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-3">
                <div class="counter-card">
                    <h3>25+</h3>
                    <p>OnGoing Projects</p>
                </div>
            </div>
        </div>
    </div>

    <div class="counter-shape-top"><img src="assets/img/counter/counter-shape.png" alt="Images"></div>
</div>




<div class="service-another pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-before sp-after">Services</span>
            <h2>We Offer Professional Solutions For Your Business</h2>
        </div>

        <div class="row pt-45">
            <div class="col-lg-3 col-md-6">
                <div class="services-another-card">
                    <a routerLink="/services-details2"><i class="fa fa-globe service-icon service-icon-bg2"></i></a>
                    <h3><a routerLink="/services-details2">Web Development</a></h3>
                    <p>We build websites that you will be proud of! We offer the latest in web technology and in latest Hosting Platforms.</p>
                    <a routerLink="/services-details2" class="learn-btn">Learn more <i class='bx bx-plus'></i></a>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="services-another-card">
                    <a routerLink="/services-details2"><i class="fa fa-mobile service-icon service-icon-bg5"></i></a>
                    <h3><a routerLink="/services-details2">Mobile App Development</a></h3>
                    <p>Get high-performance, profit-attracting and custom mobile apps. Take your app ideas to a new level.</p>
                    <a routerLink="/services-details2" class="learn-btn">Learn more <i class='bx bx-plus'></i></a>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="services-another-card">
                    <a routerLink="/services-details2"><i class="fa-brands fa-facebook-f service-icon service-icon-bg2"></i></a>
                    <h3><a routerLink="/services-details2">Social Media Marketing</a></h3>
                    <p>We offers Social media optimization services, Instagram, Facebook Page management, Regular Ads, Lead generation etc.</p>
                    <a routerLink="/services-details2" class="learn-btn">Learn more <i class='bx bx-plus'></i></a>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="services-another-card">
                    <a routerLink="/services-details2"><i class="fa-regular fa-building service-icon service-icon-bg5"></i></a>
                    <h3><a routerLink="/services-details2">e-Governance Consultancy</a></h3>
                    <p> eGovernance Consultancy services to various Government Departments including department ITcell Management. </p>
                    <a routerLink="/services-details2" class="learn-btn">Learn more <i class='bx bx-plus'></i></a>
                </div>
            </div>

         
        </div>
    </div>

    <div class="service-another-shape">
        <div class="shape-1"><img src="assets/img/shape/shape13.png" alt="Images"></div>
        <div class="shape-2"><img src="assets/img/shape/shape11.png" alt="Images"></div>
        <div class="shape-3"><img src="assets/img/shape/shape10.png" alt="Images"></div>
        <div class="shape-4"><img src="assets/img/shape/shape9.png" alt="Images"></div>
        <div class="shape-5"><img src="assets/img/shape/shape7.png" alt="Images"></div>
        <div class="shape-6"><img src="assets/img/shape/shape12.png" alt="Images"></div>
    </div>
</div>


<div class="seo-area">
    <div class="container-fluid">
        <div class="seo-width">
            <div class="row align-items-center">
                <div class="col-lg-8 col-md-12">
                    <div class="seo-form">
                        <h2>Contact with Our Experts?</h2>

                        <div class="seo-form-area">
                            <form class="seo-form-option">
                                <div class="row">
                                    <div class="col-lg-4 col-sm-6 col-md-4">
                                        <div class="form-group">
                                            <i class='fa fa-user'></i>
                                            <input class="form-control form-border" type="text" placeholder="Enter Your Name...">
                                        </div>
                                    </div>
        
                                    <div class="col-lg-4 col-sm-6 col-md-4">
                                        <div class="form-group">
                                            <i class='fa fa-phone'></i>
                                            <input class="form-control" type="text"  placeholder="Enter Contact Number">
                                        </div>
                                    </div>

                                    <div class="col-lg-4 col-sm-6 col-md-4 offset-sm-3 offset-md-0 offset-lg-0">
                                        <button type="submit" class="default-btn">
                                           Send
                                            <i class='bx bx-plus'></i>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-12">
                    <div class="seo-img">
                        <img src="assets/img/123.png" alt="Images">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>




<div class="work-area-two pt-100 pb-70" style="display:none;">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-before sp-after">Working Process</span>
            <h2 class="h2-color2">Simple & Clean Work Process</h2>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-md-12">
                <div class="work-item-list">
                    <ul>
                        <li class="text-end">
                            <h3>Frame the Problem <span>1</span></h3>
                            <p>Lorem ipsum dolor sit amet, aut odiut podit afugitsed quia consequuntur </p>
                        </li>
                        <li class="text-end">
                            <h3>Collect the Raw Data <span>2</span></h3>
                            <p>Lorem ipsum dolor sit amet, aut odiut podit afugitsed quia consequuntur </p>
                        </li>
                        <li class="text-end">
                            <h3>Process the Data <span>3</span></h3>
                            <p>Lorem ipsum dolor sit amet, aut odiut podit afugitsed quia consequuntur </p>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="work-img-2">
                    <img src="assets/img/work-img.png" alt="Images">
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="work-item-list-2">
                    <ul>
                        <li>
                            <h3><span>4</span>Explore the Data</h3>
                            <p>Lorem ipsum dolor sit amet, aut odiut podit afugitsed quia consequuntur </p>
                        </li>
                        <li>
                            <h3><span>5</span>Perform Analysis</h3>
                            <p>Lorem ipsum dolor sit amet, aut odiut podit afugitsed quia consequuntur </p>
                        </li>
                        <li>
                            <h3><span>6</span>Communicate Results</h3>
                            <p>Lorem ipsum dolor sit amet, aut odiut podit afugitsed quia consequuntur </p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>



<div class="team-area-two pb-70" style="display:none;">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-before sp-after">Team</span>
            <h2 class="h2-color2">Meet Our Data Scientist to Grow Your Business</h2> 
        </div>

        <div class="team-slider-two owl-carousel owl-theme">
            <div class="team-card active">
                <img src="assets/img/team/team-img7.jpg" alt="Images">
                <div class="content">
                    <h3>Cristiono Kopper</h3>
                    <span>Web Developer</span>
                    <ul class="social-link">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li> 
                    </ul>
                </div>
            </div>

            <div class="team-card team-rotated">
                <img src="assets/img/team/team-img8.jpg" alt="Images">
                <div class="content">
                    <h3>Jermin Jekson</h3>
                    <span>Marketing Manager</span>
                    <ul class="social-link">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li> 
                    </ul>
                </div>
            </div>

            <div class="team-card">
                <img src="assets/img/team/team-img9.jpg" alt="Images">
                <div class="content">
                    <h3>Hobler Jeain</h3>
                    <span>General Manager</span>
                    <ul class="social-link">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li> 
                    </ul>
                </div>
            </div>

            <div class="team-card team-rotated-2">
                <img src="assets/img/team/team-img10.jpg" alt="Images">\
                <div class="content">
                    <h3>Julfiker Jeain</h3>
                    <span>CEO At Ostino</span>
                    <ul class="social-link">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li> 
                    </ul>
                </div>
            </div>

            <div class="team-card active">
                <img src="assets/img/team/team-img11.jpg" alt="Images">
                <div class="content">
                    <h3>Hobler Jeain</h3>
                    <span>General Manager</span>
                    <ul class="social-link">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li> 
                    </ul>
                </div>
            </div>

            <div class="team-card team-rotated-2">
                <img src="assets/img/team/team-img12.jpg" alt="Images">
                <div class="content">
                    <h3>Julfiker Jeain</h3>
                    <span>CEO At Ostino</span>
                    <ul class="social-link">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li> 
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="team-two-shape">
        <div class="shape1"><img src="assets/img/shape/shape13.png" alt="Images"></div>
        <div class="shape2"><img src="assets/img/shape/shape11.png" alt="Images"></div>
        <div class="shape3"><img src="assets/img/shape/shape10.png" alt="Images"></div>
        <div class="shape4"><img src="assets/img/shape/shape9.png" alt="Images"></div>
        <div class="shape5"><img src="assets/img/shape/shape12.png" alt="Images"></div>
        <div class="shape6"><img src="assets/img/shape/shape7.png" alt="Images"></div>
        <div class="shape7"><img src="assets/img/shape/shape14.png" alt="Images"></div>
    </div>
</div>

<div class="testimonial-area ptb-100">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="testimonial-list">
                    <div class="list-img-1">
                        <img src="assets/img/testimonial/testimonial1.png" alt="Images">
                    </div>

                    <div class="list-img-2">
                        <img src="assets/img/testimonial/testimonial2.png" alt="Images">
                    </div>

                    <div class="list-img-3">
                        <img src="assets/img/testimonial/testimonial3.png" alt="Images">
                    </div>

                    <div class="list-img-4">
                        <img src="assets/img/testimonial/testimonial4.png" alt="Images">
                    </div>

                    <div class="list-img-5">
                        <img src="assets/img/testimonial/testimonial5.png" alt="Images">
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="testimonial-title">
                    <h2>Meet our <span style="color: #ff2500;"> Team Ipromise</span></h2>
                </div>

                <div class="testimonial-slider owl-carousel owl-theme">
                    <div class="testimonial-item">
                        <p>Founder & Chief Executive Officer of the Company.A software Professional expertized in eGovernance & web based services.</p>
                        <div class="content">
                            <img src="assets/img/testimonial/testimonial-img1.png" alt="Images">
                            <div class="content-title">
                                <h3>Sujithkumar</h3>
                                <span>CEO & Director</span>
                            </div>
                        </div>
                    </div>

                    <div class="testimonial-item">
                        <p>Creative Designer & Product Designer of the company. He has 10+ years experince in Graphic designing & brand Development</p>
                        <div class="content">
                            <img src="assets/img/testimonial/testimonial-img8.png" alt="Images">
                            <div class="content-title">
                                <h3>Sreejith Krishnan</h3>
                                <span>Creative Designer</span>
                            </div>
                        </div>
                    </div>

                    <div class="testimonial-item">
                        <p>11+ years Experince in providing eGovernance/IT support to Government deprtments.Expertized in eoffice implementation</p>
                        <div class="content">
                            <img src="assets/img/testimonial/testimonial-img2.png" alt="Images">
                            <div class="content-title">
                                <h3>Arunkumar</h3>
                                <span>eGovernance Support</span>
                            </div>
                        </div>
                    </div>

                    <div class="testimonial-item">
                        <p>7+ years Experince in providing eGOvernance/IT support to Government deprtments.Expertized in eoffice implementation</p>
                        <div class="content">
                            <img src="assets/img/testimonial/testimonial-img3.png" alt="Images">
                            <div class="content-title">
                                <h3>Krishnakumar</h3>
                                <span>eGovernance Support</span>
                            </div>
                        </div>
                    </div>


                    <div class="testimonial-item">
                        <p>Mobile APP developer,specialized in flutter and NodeJs programming. Expert in both IOS & Android app development</p>
                        <div class="content">
                            <img src="assets/img/testimonial/testimonial-img4.png" alt="Images">
                            <div class="content-title">
                                <h3>Anurag TJ</h3>
                                <span>Flutter Developer</span>
                            </div>
                        </div>
                    </div>


                    <div class="testimonial-item">
                        <p>Shefin Joy, Graphic Designer expertised in both print and web Designs</p>
                        <div class="content">
                            <img src="assets/img/testimonial/testimonial-img5.png" alt="Images">
                            <div class="content-title">
                                <h3>Shefin Joy</h3>
                                <span>Graphic Designer</span>
                            </div>
                        </div>
                    </div>


                    <div class="testimonial-item">
                        <p>5+ years Experince in providing eGOvernance/IT support to Government deprtments.Expertized in eoffice implementation</p>
                        <div class="content">
                            <img src="assets/img/testimonial/testimonial-img6.png" alt="Images">
                            <div class="content-title">
                                <h3>Saran</h3>
                                <span>eGovernance Support</span>
                            </div>
                        </div>
                    </div>


                    <div class="testimonial-item">
                        <p>Web developer having 3+ years exp in software industry.Currenlty handling web design & development of the company . Expert in Angular & Asp.net</p>
                        <div class="content">
                            <img src="assets/img/testimonial/testimonial-img7.png" alt="Images"> 
                            <div class="content-title">
                                <h3>Satheesh</h3>
                                <span>Web Developer</span>
                            </div>
                        </div>
                    </div>


              <!--    <div class="testimonial-item">
                        <p>I chose Zinka because of their value Andincredible superior customer Service they really awesome treated me like family.</p>
                        <div class="content">
                            <img src="assets/img/testimonial/testimonial-img8.png" alt="Images">
                            <div class="content-title">
                                <h3>Karthik</h3>
                                <span>Web Developer</span>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>

<div class="blog-area pt-100 pb-70" style="display:none;">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-before sp-after">Our Blogs</span>
            <h2 class="h2-color2">Latest Valuable Insights</h2>
            <p class="margin-auto">Lorem ipsum dolor sit amet consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua enim ad minim</p>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <a routerLink="/blog-details"><img src="assets/img/blog/blog1.png" alt="Images"></a>
                    <div class="content">
                        <ul>
                            <li><i class='bx bx-time-five'></i>09 April 2024</li>
                            <li><i class='bx bx-purchase-tag-alt'></i><a routerLink="/blog">Marketing</a></li>
                        </ul>
                        <h3><a routerLink="/blog-details">The Home of Technology is in Front of You</a></h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <a routerLink="/blog-details"><img src="assets/img/blog/blog2.png" alt="Images"></a>
                    <div class="content">
                        <ul>
                            <li><i class='bx bx-time-five'></i>10 July 2024</li>
                            <li><i class='bx bx-purchase-tag-alt'></i><a routerLink="/blog">SEO</a></li>
                        </ul>
                        <h3><a routerLink="/blog-details">SEO Best Practices Mobile Friendliness</a></h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="blog-card">
                    <a routerLink="/blog-details"><img src="assets/img/blog/blog3.png" alt="Images"></a>
                    <div class="content">
                        <ul>
                            <li><i class='bx bx-time-five'></i>19 June 2024</li>
                            <li><i class='bx bx-purchase-tag-alt'></i><a routerLink="/blog">SEO & Marketing</a></li>
                        </ul>
                        <h3><a routerLink="/blog-details">15 SEO Practices Website Architecture</a></h3>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="blog-shape">
        <div class="shape1"><img src="assets/img/shape/shape1.png" alt="Images"></div>
        <div class="shape2"><img src="assets/img/shape/shape5.png" alt="Images"></div>
        <div class="shape3"><img src="assets/img/shape/shape4.png" alt="Images"></div>
        <div class="shape4"><img src="assets/img/shape/shape6.png" alt="Images"></div>
    </div>
</div>

<div class="brand-logo-area">
    <div class="container-fluid">
        <div class="container-max">
            <div class="brand-logo-slider owl-carousel owl-theme">
                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo1.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo2.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo3.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo4.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo5.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo6.png" alt="Images">
                </div>
                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo7.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo8.png" alt="Images">
                </div>
            </div>
        </div>
    </div>
</div>

<div class="data-table-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="data-table-content">
                    <h2>We Like to Start Your Project With Us</h2>
                    <a routerLink="/contact" class="default-btn">Get started <i class='bx bx-plus'></i></a>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="data-table-img">
                    <img src="assets/img/tabbb.png" alt="Images">
                </div>
            </div>
        </div>
    </div>
</div>

<div class="popupcertificate" style="display: none;">
    <button style="position: absolute;
    right: 0;
    border: 1px;
    background: transparent;
    color: #333;
    font-size: 24px;
    top: 15px;
    right: 15px;" onclick="closepopup()">
        <i class="fa fa-close"></i>
    </button>
    <img src="assets/img/case-study/certificate.jpg">
</div>





<app-footer-style-two></app-footer-style-two>