import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ai-startup',
  templateUrl: './ai-startup.component.html',
  styleUrls: ['./ai-startup.component.scss']
})
export class AiStartupComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

    const toggleButton = document.getElementById('toggleButton') as HTMLButtonElement;
    const imageContainer = document.getElementById('imageContainer') as HTMLDivElement;
    const closeButton = document.getElementById('closeButton') as HTMLButtonElement;
    
    if (toggleButton && imageContainer && closeButton) {
        toggleButton.addEventListener('click', () => {
            imageContainer.style.display = 'block';
            toggleButton.style.display = 'none';
        });
    
        closeButton.addEventListener('click', () => {
            imageContainer.style.display = 'none';
            toggleButton.style.display = 'block';
        });
    }
    


  }

}
